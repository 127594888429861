export default defineNuxtRouteMiddleware(async (to, from, next) => {
  const { connected, initdone, profile } = useAuth();
  if (to.meta["@public"]) return;

  try {
    await initdone();
  } catch (e) {
    console.warn(e);
  }
  if (!connected.value)
    return navigateTo({
      path: "/login",
      query: {
        to: to.fullPath === "/logout" ? "/" : encodeURIComponent(to.fullPath),
      },
    });
  else if (
    connected.value &&
    !profile.value.role &&
    to.fullPath !== "/no-rights"
  )
    return navigateTo({ path: "/no-rights" });
});
